@import url('https://fonts.googleapis.com/css2?family=Urbanist:wght@300;400;600;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  /* Subtle, modern gradient background */
  background: linear-gradient(135deg, #f2f3f7 0%, #dadcf0 100%);
  font-family: 'Urbanist', sans-serif;
}

.payment-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

/* Payment card with a modern design */
.payment-wrapper {
  background-color: #ffffff;
  border-radius: 16px;
  width: 95%;
  max-width: 500px;
  padding: 24px;
  text-align: center;
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.1);
  animation: fadeInDown 0.5s ease;
}

/* Soft fade in */
@keyframes fadeInDown {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Title & Amount */
.title {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 12px;
  font-weight: 700;
}
.amount-label {
  font-size: 1.25rem;
  color: #444;
  margin-bottom: 20px;
  font-weight: 500;
}

/* QR Section */
.qr-section {
  margin-bottom: 24px;
}
.qr-image {
  width: 220px;
  height: 220px;
  border: 2px solid #6257fb;
  border-radius: 8px;
  padding: 10px;
}
.timer-text {
  margin-top: 10px;
  color: #6257fb;
  font-weight: 600;
}

/* Download QR (Mobile only) */
.download-btn {
  margin-top: 14px;
  background: #6257fb;
  color: #ffffff;
  border: none;
  padding: 10px 24px;
  font-size: 0.95rem;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 600;
  transition: background 0.3s;
}
.download-btn:hover {
  background: #5046ee;
}

/* Payment brand logos */
.payment-logos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
  margin-bottom: 24px;
}
.logo {
  width: 50px;
  opacity: 0.9;
  transition: opacity 0.3s;
}
.logo:hover {
  opacity: 1;
}

/* Deep link buttons container */
.app-links {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

/* Individual brand button styling */
.app-link-btn {
  display: inline-block;
  text-decoration: none;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  border-radius: 6px;
  padding: 12px 0;
  transition: opacity 0.3s;
}
.app-link-btn:hover {
  opacity: 0.9;
}

/* Brand-specific colors */
.gpay-btn {
  background: #4285f4; /* Google brand color */
}
.phonepe-btn {
  background: #5b2bf8; /* PhonePe brand color */
}
.paytm-btn {
  background: #00baf2; /* Paytm brand color */
}

/* Cancel Payment hyperlink */
.cancel-section {
  margin-top: 18px;
}
.cancel-link {
  color: #e63946; /* A modern red shade */
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  transition: color 0.3s;
}
.cancel-link:hover {
  color: #c5303a;
}

/* Success & Fail States */
.success-view,
.failed-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.circle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  animation: popIn 0.5s;
  margin-bottom: 10px;
}
.success-circle {
  background-color: #28a745;
}
.failed-circle {
  background-color: #dc3545;
}

@keyframes popIn {
  0% {
    transform: scale(0.4);
    opacity: 0.3;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Checkmark */
.checkmark {
  position: absolute;
  top: 28px;
  left: 22px;
  width: 50px;
  height: 25px;
  border-right: 5px solid #fff;
  border-bottom: 5px solid #fff;
  transform: rotate(45deg);
}

/* Crossmark */
.crossmark {
  position: absolute;
  top: 28px;
  left: 28px;
  width: 40px;
  height: 40px;
  transform: rotate(45deg);
}
.crossmark::before,
.crossmark::after {
  content: '';
  position: absolute;
  background-color: #fff;
}
.crossmark::before {
  width: 5px;
  height: 40px;
  left: 18px;
  top: 0;
}
.crossmark::after {
  width: 40px;
  height: 5px;
  left: 0;
  top: 18px;
}
