/* General Styles */
body, html {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  font-family: 'Urbanist-Light', sans-serif;
  background-color: #f8f9fa;
}

.quick-deposit-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.quick-deposit-box {
  background-color: white;
  border-radius: 15px;
  padding: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  border: 2px solid #6257fb;
  max-width: 500px;
  width: 100%;
  box-sizing: border-box;
}

h2 {
  font-size: 1.8em;
  font-weight: bold;
  color: #333;
  margin-bottom: 15px;
}

.bank-details {
  margin-bottom: 15px;
}

.bank-info {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.bank-card {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 15px;
}

.bank-card-content {
  display: flex;
  align-items: center;
}

.bank-card label {
  font-weight: bold;
  color: #333;
  margin-right: 10px;
  width: 35%;
  text-align: left;
}

.bank-card span {
  color: #555;
  flex-grow: 1;
  text-align: left;
}

.copy-icon {
  font-size: 1.2em;
  color: #6257fb;
  cursor: pointer;
  transition: color 0.3s ease;
  margin-left: 10px;
}

.copy-icon:hover {
  color: #5248d4;
}

.reference-section {
  margin-top: 20px;
  width: 100%;
}

.reference-section label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
  text-align: left;
}

.reference-section input,
.quick-deposit-button {
  width: 100%;
  padding: 12px;
  border-radius: 6px;
  font-size: 1.1em;
  margin-bottom: 15px;
  box-sizing: border-box;
}

.reference-section input {
  border: 2px solid #6257fb;
}

.quick-deposit-button {
  background-color: #6257fb;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quick-deposit-button:hover {
  background-color: #5248d4;
}

.attempts-left {
  margin-top: 10px;
  font-weight: bold;
  color: #333;
}

/* Processing Overlay Styles */
.processing-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  color: #fff;
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
}

.loader {
  position: relative;
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

.loader div {
  position: absolute;
  border: 4px solid #6257fb;
  opacity: 1;
  border-radius: 50%;
  animation: ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.loader div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

.processing-overlay p {
  font-size: 1.2em;
  margin: 10px 20px;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Success and Failure Messages */
.success-message.animate,
.failed-message.animate {
  animation: fadeIn 0.5s ease-in-out;
  font-size: 1.5em;
  margin: 20px auto;
  padding: 20px;
  border-radius: 10px;
  display: inline-block;
  max-width: 80%;
}

.success-message {
  color: #fff;
  background-color: #28a745;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.failed-message {
  color: #fff;
  background-color: #dc3545;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Notification Styles */
.notification {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #6257fb;
  color: #fff;
  padding: 15px 25px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 10000;
  animation: slideDown 0.5s ease-in-out;
}

.notification.success {
  background-color: #28a745;
}

.notification.error {
  background-color: #dc3545;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateX(-50%) translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}
